<template>
    <v-container fill-height fluid class="pa-0 ma-0 mb-10" v-if="sectionItem">
        <v-row class="ma-0 pa-0">
            <v-img :src="sectionItem.image_url != null ? sectionItem.image_url : require('@/assets/images/no-image.jpg')"
                   width="100%"
                   height="calc(100vh)"
                   position="top"
                   class="mx-0 align-end"
                   transition="slide-x-transition"
                   gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)">
                <v-row align="end" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <span class="font-weight-thin text-h5 mx-2 text-md-h2 white--text">
                               {{ sectionItem.title }}
                            </span>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row align="start" justify="center" class="ma-0 pa-0">
            <v-col cols="10">
                <div class="container--fluid fill-height mt-0 pt-5 mx-0">
                    <v-responsive class="pt-1"
                                  :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                        <span class="font-lato text-body-1 black--text"
                              v-html="sectionItem.content">
                        </span>
                    </v-responsive>
                </div>
            </v-col>
        </v-row>
        <v-row align="start" justify="center" class="ma-0 pa-0">
            <v-col cols="10">
                <v-btn outlined
                       @click="gotoSection('#contact')"
                       @click.native="scrollToId('contact')">
                    <span class="font-weight-regular text-caption">Contáctenos</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import "@fontsource/raleway";
    import "@fontsource/lato";
    import sofomPageService from '@/providers/SofomPageService';

    export default {
        name: "SofomPage",
        data: () => ({
            sectionItem: {
                id: 0,
                title: "",
                content: "",
                image_url: ""
            }
        }),
        methods: {
            gotoSection(toHash) {
                this.$router.push({path: '/homepage', hash: toHash});
            },
            scrollToId(id) {
                document.getElementById(id).scrollIntoView();
                // window.scrollBy(0, -60);
                window.scrollBy({
                    top: -60,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            formatText(text){
                let arr = [];
                // text.split('\n').forEach(item=>arr.push(`<p>${item.trim()}</p>`));
                text.split('\n').forEach(item=>arr.push(`<span>${item.trim()}</span>`));
                return arr.join('<br>');
            },
            arrayTextLine(val) {
                let lines = val.replace(/\r\n/g,"\n").split("\n");
                let textArray = [];
                for (let i=0; i < lines.length; i++) {
                    if (/\S/.test(lines[i])) {
                        textArray.push(lines[i].trim(lines[i]));
                    }
                }
                // console.log('Array de elementos texts: ', textArray);
                return textArray;
            },
            getSofom() {
                sofomPageService.getViewData().then(record => {
                    // console.log('Record: ', record.value);
                    if (record.value !== null) {
                        this.sectionItem = record.value;
                    } else {
                        this.$router.push({ name: "EmptyPage" });
                    }
                });
            }
        },
        mounted() {
            this.getSofom();
        }
    }
</script>

<style scoped>
    .font-raleway {
        font-family: "Raleway", sans-serif !important;
    }
    .font-lato {
        font-family: "Lato", sans-serif !important;
    }
    .bottom-gradient {
        background-image: linear-gradient(
            to bottom,
            rgba(114, 217, 235, 0.185) 0%,
            rgba(255, 255, 255, 0.356) 100%
        );
    }
    .panel-filter-color {
        background: rgb(176, 214, 243, 0.3) 100%;
    }
</style>